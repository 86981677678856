.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.information-graph-widget,
.electric-rates {
  /* max-width: 47.8%; */
  /* max-width: 50%; */
}

.date-picker-container {
  top: 10px;
  right: 10px;
  z-index: 10;
}

.custom-datepicker {
  border: 1px solid #ccc;
  border-radius: 4px;
  padding: 5px;
  font-size: 14px;
  width: 200px;
}

.react-datepicker__navigation--next {
  right: 10px;
}

.react-datepicker__navigation--previous {
  left: 10px;
}

.max-width-50 {
  max-width: 50%;
}

.min-width-50 {
  min-width: 50%;
}

.width-50 {
  width: 50%;
}

.min-width-full {
  min-width: 100%;
}

.invertX {
  transform: scaleX(-1);
}

.width-1px {
  width: 1px;
}

.graph-container {
  min-height: 450px;
}

.lottie-container svg {
  height: revert-layer !important;
}

.logout::before {
  content: "";
  display: inline;
  position: absolute;
  border-right: 2px solid #4b3bff;
  height: 100%;
  left: -20px;
}

.val {
  min-width: 20%;
}

.refresh-intervals {
  width: 210px;
}

.input-error {
  border: 1px solid red;
}

.battery-save-button {
  background: rgb(156, 104, 230);
}

.battery-save-button:hover {
  background: rgb(104, 21, 222);
}

.grd {
  display: grid;
  grid-template-columns: 65% 35%;
}
