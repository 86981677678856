@tailwind base;
@tailwind components;
@tailwind utilities;
:root {
  --fialova: #4b3bff;
}

body {
  background: rgb(192, 149, 255);
  background: -moz-linear-gradient(
    90deg,
    rgba(192, 149, 255, 1) 20%,
    rgba(86, 60, 255, 1) 100%
  );
  background: -webkit-linear-gradient(
    90deg,
    rgba(192, 149, 255, 1) 20%,
    rgba(86, 60, 255, 1) 100%
  );
  background: linear-gradient(
    90deg,
    rgba(192, 149, 255, 1) 20%,
    rgba(86, 60, 255, 1) 100%
  );
  font-family: "Noto Sans", sans-serif;
  margin: 0 auto;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.gradient-background {
  background: rgb(214, 188, 250);
  background: -moz-linear-gradient(
    90deg,
    rgba(214, 188, 250, 1) 20%,
    rgba(152, 143, 251, 1) 100%
  );
  background: -webkit-linear-gradient(
    90deg,
    rgba(214, 188, 250, 1) 20%,
    rgba(152, 143, 251, 1) 100%
  );
  background: linear-gradient(
    90deg,
    rgba(214, 188, 250, 1) 20%,
    rgba(152, 143, 251, 1) 100%
  );
}

.text-custom {
  color: var(--fialova);
}

.flex-3 {
  flex: 3;
}

.flex-2 {
  flex: 2;
}

.div-custom-1 {
  height: 120px;
  right: -60px;
  top: -60px;
  width: 120px;
  z-index: 1;
}

.div-custom-2 {
  height: 80px;
  left: -40px;
  top: -20px;
  width: 80px;
}

.div-custom-3 {
  bottom: -50px;
  height: 80px;
  right: -10px;
  width: 80px;
  z-index: 1;
}

.div-custom-4 {
  bottom: 120px;
  height: 100px;
  right: -50px;
  width: 100px;
  z-index: 0;
}

.div-custom:hover {
  transform: translate(5px, -5px);
}

.customButton:hover {
  background-color: #593fff;
  color: white;
}

.stat {
  color: var(--fialova);
}

/* .stat.actualPower,
.stat.acVoltage,
.stat.housePower {
  top: 45vh;
}

.stat.batterySOC,
.stat.batteryPower {
  top: 55vh;
}

.stat.actualPower {
  left: 17.5%;
}

.stat.acVoltage {
  right: 15vh;
}

.stat.batterySOC {
  right: 41vh;
}

.stat.batteryPower {
  left: 41vh;
}

.stat.housePower {
  left: 53%;
}

@media (max-width: 640px) {
  .stat.actualPower,
  .stat.acVoltage,
  .stat.housePower {
    top: 56%;
  }

  .stat.housePower {
    left: 52%;
  }

  .stat.batteryPower,
  .stat.batterySOC {
    top: 67%;
  }

  .stat.batterySOC {
    right: 31%;
  }

  .stat.batteryPower {
    left: 31%;
  }

  .stat.acVoltage {
    right: 13%;
  }
}

@media (min-width: 641px) and (max-width: 1024px) {
  .stat.actualPower,
  .stat.acVoltage,
  .stat.housePower {
    top: 57%;
  }

  .stat.batteryPower,
  .stat.batterySOC {
    top: 70%;
  }

  .stat.acVoltage {
    right: 13%;
  }

  .stat.batterySOC {
    right: 36%;
  }

  .stat.batteryPower {
    left: 36%;
  }
}

@media (min-width: 1025px) and (max-width: 1280px) {
  .stat.actualPower,
  .stat.acVoltage,
  .stat.housePower {
    top: 27dvh;
  }

  .stat.batteryPower,
  .stat.batterySOC {
    top: 36dvh;
  }

  .stat.acVoltage {
    right: 23%;
  }

  .stat.actualPower {
    left: 25%;
  }

  .stat.batterySOC {
    right: 39%;
  }

  .stat.batteryPower {
    left: 39%;
  }
}

@media (min-width: 1281px) and (max-width: 1536px) {
  .stat.actualPower,
  .stat.acVoltage,
  .stat.housePower {
    top: 27dvh;
  }

  .stat.batteryPower,
  .stat.batterySOC {
    top: 36dvh;
  }

  .stat.acVoltage {
    right: 13%;
  }

  .stat.batterySOC {
    right: 34%;
  }

  .stat.batteryPower {
    left: 34%;
  }
}

@media (min-width: 1537px) {
  .stat.actualPower,
  .stat.acVoltage,
  .stat.housePower {
    top: 27dvh;
  }

  .stat.batteryPower,
  .stat.batterySOC {
    top: 36dvh;
  }

  .stat.acVoltage {
    right: 13%;
  }

  .stat.batterySOC {
    right: 36%;
  }

  .stat.batteryPower {
    left: 36%;
  }
} */

.pointers {
  max-width: 900px;
  margin: auto;
}

.stat.acVoltage,
.stat.actualPower,
.stat.housePower {
  top: 54%;
}

.stat.actualPower {
  left: 15%;
}
.stat.housePower {
  left: 52%;
}
.stat.acVoltage {
  right: 10%;
}

.stat.batteryPower,
.stat.batterySOC {
  top: 73%;
}

.stat.batteryPower {
  left: 30%;
}

.stat.batterySOC {
  right: 30%;
}
